@use "../../variables.scss";

.form {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  &__row {
    display: flex;
    align-self: flex-end;
  }
  &__loader {
    margin-right: 20px;
  }

  &__title {
    margin: 0;
    font-family: variables.$font-heading;
  }

  &__description {
    margin: 0;
    font-family: variables.$font-content;
  }

  &__children {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > div {
      margin: 10px 0;
    }

    & > .button__container {
      align-self: flex-end;
    }
  }
}
