@use './variables.scss';

html,
body {
  margin: 0;
  padding: 0;
  background-color: white;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: variables.$font-heading;
  font-weight: 800;
  font-style: normal;
}

p {
  font-family: variables.$font-content;
  font-weight: 500;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  border: none;
}
