@use "../../variables.scss";

.list-item {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: variables.$color-item-background;
  box-shadow: variables.$shadow;
  border-radius: variables.$border-radius;
  user-select: none;
  transition: 0.3s;

  &__container {
    position: relative;
    text-decoration: none;
    color: black;
    height: 60px;
    display: flex;
    transition: 0.3s;
    cursor: pointer;

    @media (min-width: variables.$size-mobile-max) {
      &:hover {
        & > .arrow {
          opacity: 1;
          width: 40px;
          margin-left: 20px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    & > div {
      display: flex;
      align-items: center;
    }

    &__left {
      flex: 1;
      overflow: hidden;

      & > p {
        width: max-content;
        margin-right: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__right {
      width: fit-content;

      & > p {
        margin-left: 10px;
      }
    }
  }

  &__username {
    color: lightgray;
  }

  &__border {
    width: 100%;
    height: 10px;
    border-radius: 15px;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__text {
    margin: 0;
    padding: 0;
  }

  &__date {
    color: variables.$color-text-date;
  }

  &__image {
    height: 30px;
    transform: rotate(45deg);
  }

  &__accountImage {
    height: 40px;
    width: 40px;
    background-color: lightgray;
    margin-right: 10px;
    border-radius: 40px;
  }
}

.arrow {
  user-select: none;
  width: 0;
  opacity: 0;
  object-fit: contain;
  margin-left: 0;
  transition: 0.3s;
}
