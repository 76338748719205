$color-brand-primary: #000;
$color-brand-secondary: #000;
$color-brand-primary-light: #000;
$color-brand-secondary-light: #000;
$color-error: rgb(194, 0, 0);

$color-tungstun-blue: #82C9C1;
$color-tungstun-red: #F9525A;
$color-tungstun-orange: #FAB664;

$color-correct: $color-tungstun-blue;
$color-incorrect: $color-tungstun-red;

$color-auth-modal-background: #fff;

$color-divider-background: #EFEFEF;

$color-notification-background: #fff;
$color-notification-overlay: rgba(0, 0, 0, 0.05);

$color-tooltip-background: #000;
$color-tooltip-text: #fff;

$color-brand-gradient: linear-gradient(90deg, rgba(255,153,0,1) 0%, rgba(255,206,132,1) 100%);

$color-transition-background: #000;

$color-button-background: #000;
$color-button-background-hover: #0600be;
$color-button-background-active: #cecece;
$color-button-content: #fff;

$color-item-background: #fff;
$color-text-date: #cecece;

$color-menu-background: #fff;
$color-header-background: #fff;

$color-loader-background: #fff;
$color-loader-primary: #fff;
$color-loader-accent: orange;

$color-text-hint: #CECECE;
$color-text-link: #CECECE;
$color-text-link-header: #000;

$color-input-background: #EFEFEF;

$font-heading: new-rubrik, serif;
$font-content: poppins, serif;

$size-font-title: 30px;
$size-font-input: 15px;
$size-font-link: 15px;
$size-font-link-header: 18px;
$size-font-tooltip: 12px;
$size-mobile-max: 1040px;

$margin-horizontal-small: 10px;
$margin-horizontal-medium: 20px;
$margin-horizontal-large: 50px;

$border-radius: 15px;

$shadow: 0px 0px 20px 5px rgba(0,0,0,0.1);