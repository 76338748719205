@use "../../variables.scss";

.list-view {
  &__container {
    height: fit-content;
    width: 100%;
    box-sizing: border-box;

    & > * + * {
      margin-top: 20px;
      transition: 0.3s;
    }
  }

  &__search {
    flex: auto;
  }

  &__add {
    margin-left: 20px;
  }

  &__top {
    display: flex;
  }
}
