@use '../../variables.scss';

.button {
  background-color: variables.$color-button-background;
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: variables.$color-button-content;
  justify-content: center;
  z-index: 0;
  position: relative;
  overflow: hidden;
  border-radius: 1000px;
}

.button__container {
  background: none;
  border: none;
  height: 50px;
  padding: 0;  
}

.button__content {
  z-index: 2;
}

.button__overlay {
  background-color: variables.$color-button-background-hover;
  width: 0;
  height: 100%;
  border-radius: 0;
  z-index: 1;
  position: absolute;
  transition: 0.3s;
}

.button:hover > .button__overlay {
  width: 100%;
  border-radius: 100px;
}

.button:active > .button__overlay {
  background-color: variables.$color-button-background-active;
}

.button > * {
  z-index: 2;
}
