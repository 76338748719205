@use "../../variables.scss";

.popup {
  &__background {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.4s opacity;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;

    &--open {
      visibility: visible;
      opacity: 1;
    }
  }

  &__container {
    z-index: 3;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: height width 0.5s;
    background-color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__open {
      width: fit-content;

      @media (min-width: variables.$size-mobile-max) {
        height: fit-content;
        width: fit-content;
      }
    }

    &__closed {
      height: 0;
      width: 0;
      padding: 0;
    }
  }
}
