@use "../../variables.scss";

.waves {
  &__container {
    max-height: 300px;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: none;
    padding: none;
    display: flex;
    user-select: none;
    z-index: -1;
  }

  &__image {
    width: 100%;
    object-fit: fill;
    user-select: none;
  }
}
