@use "../../variables.scss";

.button {
  margin-bottom: 10px;
}

.goal {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f2f2f2;

  &__name {
    font-size: 20px;
    font-style: variables.$font-heading;
    font-weight: bold;
    margin: 10px;
  }

  &__progressBar {
    width: 100%;
    height: 20px;
    background-color: black;
    margin-top: auto;
    position: relative;

    &__progress {
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      margin: 2px 0 0 5px;
      padding: 0;
      color: white;
    }
    &__fill {
      width: 43%;
      height: 100%;
      background-color: variables.$color-loader-accent;
      z-index: 100;
    }
  }
}